import React from "react";
import { Helmet } from "react-helmet";
import { useQueryParam, StringParam } from "use-query-params";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import Layout from "../components/Layout";

const ThankYouPage = () => {
    const [message] = useQueryParam("m", StringParam);

    return (
        <Layout pageName="confirmation">
            <Helmet>
                <title>Thank You!</title>
            </Helmet>
            <Container align="justify" maxWidth="md">
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h5">Thank you!</Typography>
                    <Typography variant="body1" component="div">
                        <br />
                        {message}
                        <br />
                        <br />
                        For immediate assistance contact: <br />
                        <ul>
                            <li>
                                <a href="mailto:aimee@valleguadalupebaja.com">
                                    aimee@valleguadalupebaja.com
                                </a>
                            </li>
                            <li>
                                <a href="mailto:victoria@valleguadalupebaja.com">
                                    victoria@valleguadalupebaja.com
                                </a>{" "}
                            </li>
                            <li>
                                <a href="mailto:nicholas@valleguadalupebaja.com">
                                    nicholas@valleguadalupebaja.com
                                </a>
                            </li>
                        </ul>
                    </Typography>
                </Box>
            </Container>
        </Layout>
    );
};

export default ThankYouPage;
